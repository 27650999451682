<script setup lang="ts">
const nuxtApp = useNuxtApp();
const scrollLock = useScrollLock(document.body);
const loading = ref(false);
nuxtApp.hook("page:start", () => {
  loading.value = true;
  scrollLock.value = true;
});
nuxtApp.hook("page:finish", () => {
  loading.value = false;
  scrollLock.value = false;
});
</script>

<template>
  <UIFullScreenSpinner v-if="loading" />
</template>